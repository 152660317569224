import { UserInfo } from '../../store/user/user.state'
import * as _ from 'lodash'

export interface SubscriptionPlan {
  id: string,
  name?: string,
  payment_gateway_plan_identifier: string | PlansV2Identifier,
  old_display_price?: string,
  initial_price?: SubscriptionPlanPrice,
  price: SubscriptionPlanPrice,
  interval: SubscriptionPlanInterval,
  interval_count: number,
  status: SubscriptionPlanStatus,
  max_unique_vists: number,
  max_active_campaigns: number,
  remove_branding?: boolean,
  premium_themes?: boolean,
  description: string,
  created_at: string,
  updated_at: string,
  public?: boolean,
  misconfigured?: boolean,
  popular?: boolean,
  trial_period?: number, /* Trial Period In Days */
  trial_period_text?: string
  shopify_usage_charge: boolean,
  sms_credits: number,
  usage_plans?: UsagePlan[],
  usage_plan?: UsagePlan,
  selected_usage_plan?: string
  email_credits: number,
  trial_extension_text?: string,
  plan_cta_label?: string
  plan_cta_label_override?: string
  plan_cta_action?: 'choose_plan' | 'send_crisp_message' | 'redirect_page' | 'open_popup' | 'book_demo' | 'stripe_checkout'
  plan_cta_action_override?: 'choose_plan' | 'send_crisp_message' | 'redirect_page' | 'open_popup' | 'book_demo' | 'pay_custom_plan_invoice' | 'stripe_checkout'
  plan_cta_payload?: string
  plan_cta_payload_override?: string
  trial_period_override?: number
  altButton?: {
    plan_cta_label?: string
    plan_cta_action?: 'choose_plan' | 'send_crisp_message' | 'redirect_page' | 'open_popup'
    plan_cta_payload?: string
    display_price?: string
    trial_period_text?: string
  },
  forceGreen?: boolean
  anchor_metric: string
  version?: number
}

export enum PlansV0Identifier {
  KickStarter = 'kick_starter',
  Budding = 'budding',
  KickStarterYearly = 'kick_starter_yearly',
  Small = 'small',
  SmallYearly = 'small_yearly',
  Medium = 'medium',
  MediumYearly = 'medium_yearly',
  Pro = 'pro',
  ProYearly = 'pro_yearly',
  Enterprise = 'enterprise',
  EnterpriseMedium = 'enterprise_medium',
  EnterpriseLarge = 'enterprise_large',
  EnterpriseYearly = 'enterprise_yearly',
  EnterpriseMediumYearly = 'enterprise_medium_yearly',
  EnterpriseLargeYearly = 'enterprise_large_yearly',
  EnterpriseXl = 'enterprise_xl',
  EnterpriseXlYearly = 'enterprise_xl_yearly',
  EnterpriseXxl = 'enterprise_xxl',
  EnterpriseXxlYearly = 'enterprise_xxl_yearly',
  EnterpriseXxxl = 'enterprise_xxxl',
  EnterpriseXxxlYearly = 'enterprise_xxxl_yearly',
  EnterpriseXxxxl = 'enterprise_xxxxl',
  EnterpriseXxxxlYearly = 'enterprise_xxxxl_yearly',
}

export enum PlansV1Identifier {
  Free = 'new_free',
  Starter = 'new_starter',
  Basic = 'new_basic',
  Pro = 'new_pro',
  Enterprise = 'new_enterprise',
  Custom = 'new_custom',
}

export enum PlansV2Identifier {
  Free = 'new_free_v2',
  Starter = 'new_starter_v2',
  Basic = 'new_basic_v2',
  Pro = 'new_pro_v2',
  Enterprise = 'new_enterprise_v2',
  Custom = 'new_custom_v2',
}

export enum PlansV3Identifier {
  Free = 'new_free_v3',
  Starter = 'new_starter_v3',
  Basic = 'new_basic_v3',
  Pro = 'new_pro_v3',
  Enterprise = 'new_enterprise_v3',
  Custom = 'new_custom_v3',
  Stripe = 'new_custom_stripe_mvp',
  NewCustomFree = 'new_custom_free_v3',
  NewCustomPaid = 'new_custom_paid_v3',
  LocalCustom = 'new_custom_local_v3',
}

export interface UsagePlan {
  created_at: string
  id: string
  max: number
  metric: string
  min: number
  price: SubscriptionPlanPrice
  product: string
  title: string
  updated_at: string
  plan_id?: string // added locally for storing reference to the plan
  disabled?: boolean // added locally for disabling in dropdown
  selected?: boolean // added locally for selecting in dropdown
  customPriceTier?: boolean // added locally for custom price tier
}

export interface SubscriptionPlanPrice {
  fractional: string,
  currency: SubscriptionPlanPriceCurrency
}

export enum SubscriptionPlanInterval {
  Month = 'month',
}

export enum SubscriptionPlanStatus {
  Active = 'active',
}

export interface SubscriptionPlanPriceCurrency {
  id: string,
  alternate_symbols: string[],
  decimal_mark: string,
  disambiguate_symbol: string,
  html_entity: string,
  iso_code: string,
  iso_numeric: string,
  name: string,
  priority: number,
  smallest_denomination: number,
  subunit: string,
  subunit_to_unit: number,
  symbol: string,
  symbol_first: boolean,
  thousands_separator: string,

}

export class SubscriptionPlanLimit {
  parent_class_name?: string
  class_name?: string
  tooltip?: string
  active: boolean
  description: string
  sub_description?: string
}

export class SubscriptionPlanFeature {
  parent_class_name?: string
  class_name?: string
  active: boolean
  description: string
  tooltip?: string
  crispOverlay?: {
    text?: string
    message?: string
  }
}

export interface SubscriptionPlanData {
  title: string
  subtitle?: string
  old_display_price?: string
  price: {
    amount: string,
    period: string,
    custom?: string,
    custom_with_price_and_period?: boolean,
    cents?: string,
    class?: string,
    size?: number
  }
  trial?: {
    period?: string
  }
  trial_period?: number
  trial_period_text?: string
  isBudding?: boolean
  limits: SubscriptionPlanLimit[]
  features: SubscriptionPlanFeature[]
  trial_extension_text?: string
  customSuperScript?: string
  isFree?: boolean
  isCustom?: boolean
  isStripePlan?: boolean
  isPopular?: boolean
  isRecommended?: boolean
  order?: number
  plan_cta_label?: string
  plan_cta_label_override?: string
  plan_cta_label_due_date?: string
  current?: boolean
  selected?: boolean
  plan_cta_action?: 'choose_plan'  | 'send_crisp_message' | 'redirect_page' | 'open_popup' | 'book_demo'
  plan_cta_action_override?: 'choose_plan'  | 'send_crisp_message' | 'redirect_page' | 'open_popup' | 'book_demo' | 'pay_custom_plan_invoice' | 'stripe_checkout'
  plan_cta_payload?: string
  plan_cta_payload_override?: string
  plan_cta_color?: string
  teaserCode?: {
    priceText?: string
    buttonText?: string
  },
  altButton?: any
  content?: any
  disabled?: boolean
  subtext?: string
  chip?: string
  payment_gateway_plan_identifier?: string
  isCustomPriceTier?: boolean
  akcAbove5k?: boolean
  pickPlanLaterEligible?: boolean
}

export class SubscriptionPlanMaker {
  static getLimits(plan: SubscriptionPlan): SubscriptionPlanLimit[] {
    if (plan.payment_gateway_plan_identifier.includes('_yearly')) {
      return [
        {
          parent_class_name: '_limitBox',
          class_name: '_limit',
          description: `${plan.max_unique_vists.toLocaleString('en-US')}`,
          sub_description: `${(plan.max_unique_vists / 2).toLocaleString('en-US')}`,
          active: true,
        },
        {
          parent_class_name: '_limitUnitsBox',
          class_name: '_limitUnits',
          description: 'visitors/month',
          tooltip: 'Monthly Tracked Visitors (MTV) are the number of unique visits across your site or app. We only count visitors once per month, even if he/she visits multiple times during the month.',
          active: true,
        },
      ]
    }
    return [
      {
        parent_class_name: '_limitBox',
        class_name: '_limit',
        description: `${plan.max_unique_vists.toLocaleString('en-US')}`,
        active: true,
      },
      {
        parent_class_name: '_limitUnitsBox',
        class_name: '_limitUnits',
        description: 'visitors/month',
        tooltip: 'Monthly Tracked Visitors (MTV) are the number of unique visits across your site or app. We only count visitors once per month, even if he/she visits multiple times during the month.',
        active: true,
      },
    ]
  }

  static getTrialPeriod(plan: SubscriptionPlan, shopify: boolean = false, availableTrialDays: number = 0): string {
    if (plan.payment_gateway_plan_identifier === 'budding') {
      if (shopify) {
        return 'Start Free Plan'
      } else {
        return 'Use FREE'
      }
    }
    if (availableTrialDays) {
      if (shopify) {
        return 'Start Free Trial'
      } else {
        return `Start ${availableTrialDays}-Day Free Trial`
      }
    }
    if (!!plan.old_display_price) {
      return 'Redeem Offer'
    }
    return 'Select Plan'
  }

  static getFeatures(plan: SubscriptionPlan, user: UserInfo): SubscriptionPlanFeature[] {
    const shopType = _.get(user, 'shop.type')
    const maxActiveCampaigns = _.max([plan.max_active_campaigns, (plan.max_active_campaigns + _.get(user, 'subscription.rewarded_campaigns_count'))])
    switch (plan.payment_gateway_plan_identifier) {
      case 'budding':
        return [
          {
            parent_class_name: '_dividerBox',
            class_name: '_divider',
            description: `<hr/>`,
            active: true,
          },
          ...this.getBaseFeatures(shopType),
          {
            description: '20+ Languages',
            active: true,
          },
          {
            description: `${maxActiveCampaigns} Active Campaign${maxActiveCampaigns === 1 ? '' : 's'}`,
            active: true,
          },
          {
            description: 'Custom Theme Builder',
            active: true,
          },
          {
            description: 'PLUS Themes',
            active: plan.premium_themes,
          },
          {
            description: 'Removable Branding',
            active: plan.remove_branding,
          },
          {
            description: 'Live Chat Support',
            active: true,
          },
        ]
      case 'small':
      case 'small_yearly':
      case 'kick_starter':
      case 'kick_starter_yearly':
        return [
          {
            parent_class_name: '_dividerBox',
            class_name: '_divider',
            description: `<hr/>`,
            active: true,
          },
          ...this.getBaseFeatures(shopType),
          // {
          //   description: 'PLUS Support',
          //   active: true,
          // },
          {
            description: '20+ Languages',
            active: true,
          },
          {
            description: `${maxActiveCampaigns} Active Campaign${maxActiveCampaigns === 1 ? '' : 's'}`,
            active: true,
          },
          {
            description: 'Custom Theme Builder',
            active: true,
          },
          {
            description: 'PLUS Themes',
            active: plan.premium_themes,
          },
          {
            description: 'Removable Branding',
            active: plan.remove_branding,
          },
        ]
      case 'pro':
      case 'pro_yearly':
      case 'enterprise':
      case 'enterprise_medium':
      case 'enterprise_large':
      case 'enterprise_yearly':
      case 'enterprise_medium_yearly':
      case 'enterprise_large_yearly':
      default:
        return [
          {
            parent_class_name: '_dividerBox',
            class_name: '_divider',
            description: `<hr/>`,
            active: true,
          },
          ...this.getBaseFeatures(shopType),
          // {
          //   description: 'Elite Support',
          //   active: true,
          // },
          {
            description: '20+ Languages',
            active: true,
          },
          {
            description: maxActiveCampaigns === 9999 ? 'Unlimited Active Campaigns' : `${maxActiveCampaigns} Active Campaign${maxActiveCampaigns === 1 ? '' : 's'}`,
            active: true,
          },
          {
            description: 'Custom Theme Builder',
            active: true,
          },
          {
            description: 'PLUS Themes',
            active: plan.premium_themes,
          },
          {
            description: 'Removable Branding',
            active: plan.remove_branding,
          },
        ]
    }
    return [
      ...this.getBaseFeatures(shopType),
    ]
  }

  static getActiveCampaignFeatures(plan: SubscriptionPlan, user: UserInfo, smsEligible: boolean = false): SubscriptionPlanFeature[] {
    const maxActiveCampaigns = _.max([plan.max_active_campaigns, (plan.max_active_campaigns + _.get(user, 'subscription.rewarded_campaigns_count'))])
    const smsCreditsCount = plan.sms_credits || (plan.max_unique_vists / 10)

    let smsFeature: SubscriptionPlanFeature = null
    let automationDescr = 'Email Automations'
    let broadcastDescr = 'Email Broadcasts'
    let popupsDescr = 'Pop Ups - Email/BDay'

    if (smsEligible) {
      smsFeature = {
        description: `${smsCreditsCount.toLocaleString('en-US')} SMS Credits`,
        active: true,
        tooltip: `<p class='mb-2'>${(plan.sms_credits || (plan.max_unique_vists / 10)).toLocaleString('en-US')} SMS free starter credits included and will rollover until completely used. Once used you will need to reload your credits by purchasing more at market rates:</p>
                  <p class='text-center m-0'>SMS for $0.01/message</p>
                  <p class='text-center m-0'>MMS for $0.03/message</p>`,
      }
      automationDescr = 'Email/SMS Automations'
      broadcastDescr = 'Email/SMS Broadcasts'
      popupsDescr = 'Pop Ups - SMS/Email/BDay'
    } else {
      // for now hide sms from plans for users that are not eligible
      // smsFeature = {
      //   description: `<one-icon class="mr-1" name="lock-closed-outline"></one-icon> ${smsCreditsCount.toLocaleString('en-US')} SMS Credits`,
      //   active: true,
      //   tooltip: `SMS automations are not supported for your location by default,
      //             click to contact support about this issue.`,
      //   crispOverlay: {
      //     text: '<one-icon class="mr-1" name="lock-closed-outline"></one-icon> Contact Support',
      //     message: 'Hello Team, I need help with activating SMS automations for my location.'
      //   }
      // }
    }
    switch (plan.payment_gateway_plan_identifier) {
      case 'budding':
        return [
          {
            parent_class_name: '_dividerBox',
            class_name: '_divider',
            description: `<hr/>`,
            active: true,
          },
          {
            description: 'Unlimited Page Views',
            active: true,
          },
          // {
          //   description: `${plan.email_credits.toLocaleString('en-US')} Email Sends/month`,
          //   active: true,
          // },
          // {
          //   description: 'Unlimited Contacts',
          //   active: true,
          // },
          // smsFeature,
          {
            description: `${maxActiveCampaigns} Active Campaign${maxActiveCampaigns === 1 ? '' : 's'}`,
            active: true,
          },
          // {
          //   description: automationDescr,
          //   active: true,
          // },
          // {
          //   description: broadcastDescr,
          //   active: true,
          // },
          {
            description: popupsDescr,
            active: true,
          },
          {
            description: 'Gamification - Spin Wheel',
            active: true,
          },
          {
            description: 'Social Proof - Sales Pop',
            active: true,
          },
          // {
          //   description: 'Lists & Segments',
          //   active: true,
          // },
          {
            description: 'Custom Targeting & Page Rules',
            active: true,
          },
          {
            description: 'Custom Theme Builder',
            active: true,
          },
          {
            description: '18+ Automation Integrations',
            active: true,
          },
          {
            description: 'Conversion Analytics',
            active: true,
          },
          {
            description: '20+ Languages',
            active: true,
          },
        ]
      default:
        return [
          {
            parent_class_name: '_dividerBox',
            class_name: '_divider',
            description: `<hr/>`,
            active: true,
          },
          {
            description: 'All Features',
            active: true,
          },
          // {
          //   description: `${plan.email_credits.toLocaleString('en-US')} Email Sends/month`,
          //   active: true,
          // },
          // smsFeature,
          {
            description: maxActiveCampaigns === 9999 ? 'Unlimited Active Campaigns' : `${maxActiveCampaigns} Active Campaign${maxActiveCampaigns === 1 ? '' : 's'}`,
            active: true,
          },
        ]
    }
  }

  static getSubtitle(plan: SubscriptionPlan): string {
    switch (plan.payment_gateway_plan_identifier) {
      case 'budding':
        return 'For solo-preneurs just getting started'
      case 'kick_starter':
      case 'kick_starter_yearly':
        return 'for up and coming businesses'
      case 'small':
      case 'small_yearly':
        return 'Most popular and cost effective plan'
      case 'medium':
      case 'medium_yearly':
        return 'For scaling businesses '
      case 'pro':
      case 'pro_yearly':
        return 'For growing brands'
      case 'enterprise':
      case 'enterprise_medium':
      case 'enterprise_large':
      case 'enterprise_yearly':
      case 'enterprise_medium_yearly':
      case 'enterprise_large_yearly':
      case 'enterprise_xl':
      case 'enterprise_xl_yearly':
      case 'enterprise_xxl':
      case 'enterprise_xxl_yearly':
      case 'enterprise_xxxl':
      case 'enterprise_xxxl_yearly':
      case 'enterprise_xxxxl':
      case 'enterprise_xxxxl_yearly':
        return 'For established brands'
    }
    return ''
  }

  static getBaseFeatures(type: string) {
    switch (type) {
      case 'ShopifyShop':
        return [
          {
            description: 'Unlimited Page Views/Impressions',
            active: true,
          },
          {
            description: 'Unlimited Subscribers/Contacts',
            active: true,
          },
          {
            description: 'Social Proof - Sales Pop',
            active: true,
          },
          {
            description: 'Gamification - Spin Wheel',
            active: true,
          },
          {
            description: 'Pop Ups - SMS/Email/BDay',
            active: true,
          },
          {
            description: 'Custom Timing & Page Rules',
            active: true,
          },
          {
            description: 'Unique Coupon Codes',
            active: true,
          },
          {
            description: 'Email/SMS Automation Integrations',
            active: true,
          },
          {
            description: 'Traffic & Order Analytics',
            active: true,
          },
        ]
      case 'WooCommerceShop':
        return [
          {
            description: 'Unlimited Page Views/Impressions',
            active: true,
          },
          {
            description: 'Unlimited Subscribers/Contacts',
            active: true,
          },
          {
            description: 'Social Proof - Sales Pop',
            active: true,
          },
          {
            description: 'Gamification - Spin Wheel',
            active: true,
          },
          {
            description: 'Pop Ups - SMS/Email/BDay',
            active: true,
          },
          {
            description: 'Custom Timing & Page Rules',
            active: true,
          },
          {
            description: 'Custom Theme Builder',
            active: true,
          },
          {
            description: 'Email/SMS Automation Integrations',
            active: true,
          },
          {
            description: 'Traffic & Order Analytics',
            active: true,
          },
        ]
    }
    return [
      {
        description: 'Unlimited Page Views/Impressions',
        active: true,
      },
      {
        description: 'Unlimited Subscribers/Contacts',
        active: true,
      },
      {
        description: 'Social Proof - Sales Pop',
        active: true,
      },
      {
        description: 'Gamification - Spin Wheel',
        active: true,
      },
      {
        description: 'Pop Ups - SMS/Email/BDay',
        active: true,
      },
      {
        description: 'Custom Timing & Page Rules',
        active: true,
      },
      {
        description: 'Custom Theme Builder',
        active: true,
      },
      {
        description: 'Email/SMS Automation Integrations',
        active: true,
      },
      {
        description: 'Conversion Analytics',
        active: true,
      },
    ]
  }

}
