import { Component, OnDestroy, OnInit } from '@angular/core'
import { Subscription } from 'rxjs'
import { SideNavigationStatusColor } from '../../../../shared/modules/side-navigation-bar/enums/side-navigation-status-color.enum'
import { UpsellStatus } from '../../models/upsell-page.models'
import { UpsellBuilderStepsService } from '../../services/upsell-builder-steps.service'
import { RouteHeaderUrl } from '../../../../shared/components/one-header/header-navigation.model'

@Component({
    templateUrl: './upsell-builder.component.html',
    styleUrls: ['./upsell-builder.component.scss'],
    standalone: false
})
export class UpsellBuilderComponent implements OnInit, OnDestroy {
  headline = 'Checkout Upsell'
  subHeadline = 'Upsell is'
  statusLabel: string = UpsellStatus.Off
  statusColor = SideNavigationStatusColor.Grey
  backButtonLabel = 'Campaign'
  backButtonUrl = '/upsell'
  steps$ = this.stepService.steps$
  isSideBarPinned: boolean = false
  RouteHeaderUrl = RouteHeaderUrl
  private subscription$ = new Subscription()

  constructor (
      private stepService: UpsellBuilderStepsService,
  ) {}

  ngOnInit(): void {
    this.subscription$.add(
      this.stepService.status$.asObservable().subscribe(status => {
        this.statusLabel = status === UpsellStatus.Live ? 'Live' : status
        switch (status) {
          case UpsellStatus.Live:
            this.statusColor = SideNavigationStatusColor.Green
            break
          case UpsellStatus.Paused:
            this.statusColor = SideNavigationStatusColor.Yellow
            break
          case UpsellStatus.Off:
            this.statusColor = SideNavigationStatusColor.Grey
            break
          case UpsellStatus.Invalid:
            this.statusColor = SideNavigationStatusColor.Red
            break
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe()
  }
}
