import { BrandingService } from './../../../core/services/branding.service';
import { Injectable } from '@angular/core'
import { ApiService } from '../../../core/services/api/api.service'
import { MarketingEmail, MarketingEmailSchedulingMethod, MarketingEmailType } from '../../../shared/models/campaign/marketing-email'
import { BroadcastEmail, BroadcastEmailStatus } from '../../../shared/models/broadcast-email.model'
import moment from 'moment'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { EmailPageDateRange, TableType } from '../enums/email-page.models'
import { CampaignDisplayStatusToFilterStatus } from '../../../shared/models/campaign/campaign'
import { finalize, map, switchMap, tap } from 'rxjs/operators'
import { HideLoading, ShowLoading } from '../../../store/loading/loading.actions'
import { StoreState } from '../../../store/store.state'
import { Store } from '@ngrx/store'
import { UserService } from '../../../core/services/user.service'
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from '../../../shared/components/modals/confirm.modal/confirm.modal.component';

@Injectable()
export class EmailsService {

  private startDate = moment().utc().subtract(29, 'days').format('YYYY-MM-DD')
  private endDate = moment().utc().format('YYYY-MM-DD')
  // private endDate = moment().utc().subtract(1, 'day').format('YYYY-MM-DD')

  public dateRange$: BehaviorSubject<EmailPageDateRange> = new BehaviorSubject({
    start_date: this.startDate,
    end_date: this.endDate,
  })

  public refreshTable$: BehaviorSubject<TableType> = new BehaviorSubject(null)

  constructor(
    private apiService: ApiService,
    private store: Store<StoreState>,
    private userService: UserService,
    private dialog: MatDialog,
    private brandingService: BrandingService
  ) {
  }

  getEmails(params: { page: number, limit: number, status: any, campaign_status?: string, start_date?: string, end_date?: string }) {
    let filterQuery = ''
    if (params.campaign_status) {
      const filterStatus = CampaignDisplayStatusToFilterStatus[params.campaign_status]
      filterQuery = `&campaign_status=${filterStatus}`
    }
    if (params.start_date && params.end_date) {
      filterQuery += `&date_range_type=custom_date&start_date=${params.start_date}&end_date=${params.end_date}`
    }
    return this.apiService.get(`/v1/me/marketing_emails?page=${params.page}&limit=${params.limit}${filterQuery}`)
  }

  getEmailStats(start_date: string, end_date: string) {
    return this.apiService.get(`/v1/me/stats/messaging/email?start_date=${start_date}&end_date=${end_date}`)
      .pipe(
        finalize(() => this.store.dispatch(new HideLoading('EmailsStats')))
      )
  }

  getAllCampaigns() {
    return this.apiService.get('/v1/campaigns')
  }

  public scheduleText(mail: MarketingEmail) {
    if (!mail.can_destroy) {
      if (mail.type === MarketingEmailType.SubscriptionConfirmationEmail) {
        return 'Sends during the signup'
      }
      return 'Sends immediately after signup'
    } else {
      if (mail.scheduling_method === MarketingEmailSchedulingMethod.Fixed) {
        return `Sends on ${moment(mail.scheduling_date).format('M/D/YYYY, hh:mm A')}`
      } else {
        return `Sends ${mail.scheduling_value} days after signup`
      }
    }
  }

  getBroadcastEmails(params: {
    page: number,
    limit: number,
    status: any,
    start_date?: string,
    end_date?: string,
    include_template_data?: boolean,
    name?: string
  }): Observable<{ data: BroadcastEmail[], total_count: number, total_pages: number }> {
    let url = `/v1/me/broadcast_emails?page=${params.page}&limit=${params.limit}`
    if (params.start_date && params.end_date) {
      url += `&date_range_type=custom_date&start_date=${params.start_date}&end_date=${params.end_date}`
    }
    if (params.status && params.status !== 'all') {
      url += `&status=${params.status}`
    }
    if (params.status && params.status !== 'all') {
      url += `&status=${params.status}`
    }
    if (params.include_template_data) {
      url += `&include_template_data=true`
    }
    if (params.name) {
      url += `&name=${params.name}`
    }
    return this.apiService.get(url)
  }

  crateBroadcastEmail(email: BroadcastEmail) {
    return this.apiService.post('/v1/me/broadcast_emails', email)
  }

  duplicateBroadcastEmail(id: string, payload: { name: string, scheduled_at: string }): Observable<BroadcastEmail> {
    return this.apiService.post(`/v1/me/broadcast_emails/${id}/duplicate`, payload)
  }

  getBroadcastEmailTemplateData(id: string) {
    return this.apiService.get(`/v1/me/broadcast_emails/${id}`)
      .pipe(map(res => res.template_data))
  }


  getBroadcastEmail(id: string): Observable<BroadcastEmail> {
    return this.apiService.get(`/v1/me/broadcast_emails/${id}`).pipe(tap(res=> {
      if (res.template_data?.template_json?.body) {
        this.brandingService.setEmailBrandingLogoBody(res.template_data.template_json.body)
      }
    }))
  }

  updateBroadcastEmail(id: string, email: BroadcastEmail) {
    // FIXME: when we don't need to check timing anymore, keep this next line and remove everything else
    // return this.apiService.put(`/v1/me/broadcast_emails/${id}`, email)
    const shouldCheckTiming = this.usersToBeTimedOut.includes(this.userService.userInfo.id)
    if (email.active) {
      if (shouldCheckTiming) {
        return this.tempTimeoutCrutch(id, email.active, this.apiService.put(`/v1/me/broadcast_emails/${id}`, email))
      } else {
        return this.apiService.put(`/v1/me/broadcast_emails/${id}`, email)
      }
    } else {
      return this.apiService.put(`/v1/me/broadcast_emails/${id}`, email)
    }
  }

  toggleBroadcastEmail(id: string, value: boolean) {
    // FIXME: when we don't need to check timing anymore, keep this next line and remove everything else
    // return this.apiService.put(`/v1/me/broadcast_emails/${id}`, {active: value})
    const shouldCheckTiming = this.usersToBeTimedOut.includes(this.userService.userInfo.id)
    this.store.dispatch(new ShowLoading('ToggleBroadcastEmail'))
    if (!shouldCheckTiming || !value) {
      return this.apiService.put(`/v1/me/broadcast_emails/${id}`, {active: value}).pipe(
        finalize(() => this.store.dispatch(new HideLoading('ToggleBroadcastEmail')))
      )
    } else {
      return this.tempTimeoutCrutch(id, value, this.apiService.put(`/v1/me/broadcast_emails/${id}`, {active: value})).pipe(
        finalize(() => this.store.dispatch(new HideLoading('ToggleBroadcastEmail')))
      )
    }
  }

  archiveBroadcastEmail(id: string) {
    return this.apiService.delete(`/v1/me/broadcast_emails/${id}`)
  }

  restoreBroadcastEmail(id: string) {
    return this.apiService.put(`/v1/me/broadcast_emails/${id}/restore`)
  }

  sendBroadcastSampleEmail(payload: any) {
    return this.apiService.post(`/v1/me/broadcast_emails/send_sample_email`, payload)
  }

  sendAutomationSampleEmail(payload: any) {
    return this.apiService.post(`/v1/me/email_automations/send_sample_email`, payload)
  }

  get usersToBeTimedOut() {
    return [
      'caf62bc8-4395-4ddf-8cc9-22506305fa4c',
    ]
  }

  tempTimeoutCrutch(currentEmailId: string, value: boolean, request: Observable<any>) {
    return this.getBroadcastEmails({
      page: 1,
      limit: 100,
      status: 'all',
      include_template_data: false,
    }).pipe(
      switchMap((res) => {
        const emails: BroadcastEmail[] = res.data
        const currentEmail = emails.find(email => email.id === currentEmailId)
        const otherEmails = emails.filter(email => email.id !== currentEmailId)
        // check if any of existing broadcast emails scheduled_at is within 30 minutes of currentEmail.scheduled_at
        const scheduledWithin30Minutes = otherEmails.filter(email => {
          const diff = moment(email.scheduled_at).diff(moment(currentEmail.scheduled_at), 'minutes')
          return Math.abs(diff) < 30
        })
        if (scheduledWithin30Minutes.length > 0) {
          const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: {
              title: 'Schedule Conflict',
              headerClass: 'text-center',
              text: 'Please schedule this email broadcast for at 30 minutes after your previous broadcast',
              acceptButton: {
                text: 'Close',
                classes: 'pf-button filled black',
              },
              footerClass: 'mb-0 p-0 justify-content-center'
            },
          })
          return of(null)
        } else {
          return request
        }
      })
    )
  }
}
